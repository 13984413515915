import {get, post} from '@/utils/http'
import {Url} from './url'

class Ajax {
	// 获取微信用户信息
	getWxUserInfo() {
		return get(Url.wxUserInfo)
	}

	// 查询所有绑定账户
	getAllAccount() {
		return get(Url.allAccount)
	}

	// 绑定手机号户号
	bindAccount({guestNo, phoneNum, userId}) {
		return post(Url.bindAccount, {
			guestNo: guestNo,
			phoneNum: phoneNum,
			userId: userId
		})
	}

	//更新微信用户信息
	updateWxUserInfo(userInfo) {
		return post(Url.newWxUserInfo, userInfo)
	}

	// 查询账户详情缴费金额等
	getAccountDetail({bindId}) {
		return get(Url.accountDetail, {bindId})
	}

	// 获取缴费记录
	getIncomeRecord({bindId}) {
		return get(Url.incomeRecord, {bindId})
	}

	// 获取用水明细
	getUseWater({bindId}) {
		return get(Url.useWater, {bindId})
	}

	// 切换账户
	switchAccount({bindId, userId}) {
		return post(Url.switchAccount, {
			bindId: bindId,
			userId: userId
		})
	}

	// 解绑手机号户号
	unboundAccount({bindId, userId}) {
		return post(Url.unboundAccount, {
			bindId: bindId,
			userId: userId
		})
	}

	// 获取“JSAPI调起支付API”所需的签名、随机串、时间戳等参数  片区模式
	getJSApiPay({payChannel, bindId, amount, totalAmount}) {
		return post(Url.jsApiPay, {payChannel, bindId, amount, totalAmount})
	}

	// 获取“JSAPI调起支付API”所需的签名、随机串、时间戳等参数 租户模式
	getJSApiPayByTenant({payChannel, bindId, amount, totalAmount, uniqueId, mchType = 0}) {
		return post(`${Url.jsApiPayByTenant}${mchType}`, {payChannel, bindId, amount, totalAmount, uniqueId})
	}

	getWxp() {
		return get(Url.getWxp, )
	}

	//发送验证码
	sendCode({phoneNum}) {
		return get(Url.sendSms, {phoneNum})
	}

	//一键同步
	guestNoSync({userId, phoneNum, smsVerifyCode}) {
		return post(Url.guestNoSync, {userId, phoneNum, smsVerifyCode})
	}
}

export default new Ajax()
